:root {
  --gray: #666666;
  --black: #333333;
  --orange: #ff7c08;
  //--green: #73af00;
  --green: #03b95c;
  --red: #ff4b55;
  --dark: #000;
}
.dark {
  color: var(--dark);
}
.red {
  color: var(--red);
}
.gray {
  color: var(--gray);
}
.black {
  color: var(--black) !important;
}
.orange {
  color: var(--orange) !important;
}
.green {
  color: var(--green);
}

.__green {
  color: var(--green) !important;
}
.__red {
  color: var(--red) !important;
}
.__gray {
  color: var(--gray) !important;
}
.__orange {
  color: var(--orange) !important;
}

.gray-bg {
  background-color: var(--gray);
}
.black-bg {
  background-color: var(--black) !important;
}
.orange-bg {
  background-color: var(--orange) !important;
}
.green-bg {
  background-color: var(--green);
}
/*//////////////////////////////////////////////////////////////////
[ FONT initialization ]*/
@font-face {
  font-family: ft-light;
  src: url("./assets/fonts/Montserrat/Montserrat-Thin.ttf");
}
.ft-light {
  font-family: ft-light;
}
@font-face {
  font-family: ft-medium;
  src: url("./assets/fonts/Montserrat/Montserrat-Medium.ttf");
}
.ft-medium {
  font-family: ft-medium;
}
@font-face {
  font-family: ft-regular;
  src: url("./assets/fonts/Montserrat/Montserrat-Regular.ttf");
}
.ft-regular {
  font-family: ft-regular;
}
@font-face {
  font-family: ft-semi;
  src: url("./assets/fonts/Montserrat/Montserrat-SemiBold.ttf");
}
.ft-semi {
  font-family: ft-semi;
}
@font-face {
  font-family: ft-bold;
  src: url("./assets/fonts/Montserrat/Montserrat-Bold.ttf");
}
.ft-bold {
  font-family: "ft-bold" !important;
}
/*************************/

/*//////////////////////////////////////////////////////////////////
[ RESTYLE TAG ]*/
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
body {
  font-family: ft-medium;
  font-size: 14px;
}
html {
  scroll-behavior: smooth;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0px;
  }
  ul,
  li {
    margin: 0px;
    list-style-type: none;
  }
  a {
    cursor: pointer;
    text-decoration: none;
    font-size: 14px;
    line-height: 1.7;
    color: #666666;
    margin: 0px;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    :focus {
      outline: none !important;
    }
    :hover {
      text-decoration: none;
      color: initial;
    }
  }
  p {
    font-size: 14px;
    line-height: 1.7;
    margin: 0px;
  }
  textarea {
    :focus {
      :-webkit-input-placeholder {
        color: transparent;
      }
      :-moz-placeholder {
        color: transparent;
      }
      :-moz-placeholder {
        color: transparent;
      }
      :-ms-input-placeholder {
        color: transparent;
      }
    }
  }
  input {
    outline: none;
    border: none;
    :-webkit-input-placeholder {
      color: #333;
    }
    :-moz-placeholder {
      color: #333;
    }
    :-moz-placeholder {
      color: #333;
    }
    :-ms-input-placeholder {
      color: #333;
    }
    &:focus {
      outline: none !important;
      box-shadow: none !important;
      :-webkit-input-placeholder {
        color: transparent;
      }
      :-moz-placeholder {
        color: transparent;
      }
      :-moz-placeholder {
        color: transparent;
      }
      :-ms-input-placeholder {
        color: transparent;
      }
    }
  }
  .__palceholder::placeholder {
    color: #999 !important;
  }
  button {
    outline: none !important;
    border: none;
    background: transparent;
    -webkit-appearance: none;
    appearance: none;
    :focus {
      outline: none !important;
      box-shadow: none !important;
    }
    :hover {
      cursor: pointer;
    }
  }
  .cursor {
    cursor: pointer;
  }
}
textarea:-moz-placeholder {
  color: #333;
}
textarea::-moz-placeholder {
  color: #333;
}
textarea:-ms-input-placeholder {
  color: #333;
}
/*---------------------------------------------*/
/*---------------------------------------------*/

/*//////////////////////////////////////////////////////////////////
[ Map ]*/
.map__ {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background: #f2f2f2;
  img {
    width: 100%;
    height: 100%;
  }
}

/*------------------------------------------------------------------
[ Common CSS ]*/
.__main {
  height: 100vh;
  overflow: hidden;
  display: flex;
}
// }

//sidebar
.__sidebar {
  margin: 25px;
  width: 350px;
  display: flex;
  flex-direction: column;
  transition: 1.5s;
  float: left;
  z-index: 1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  .__sidebar__in {
    -webkit-transition: 0.5s;
    transition: 0.5s;
    border-radius: 8px;
    background: linear-gradient(
      140.89deg,
      rgba(244, 120, 36, 0.33) 49.26%,
      rgba(12, 103, 200, 0.33) 94.94%
    );
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(9px);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    height: calc(100% - 85px);
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: auto;
    justify-content: flex-end;
    .__toggle {
      text-align: center;
      button {
        background: #f2f2f2;
        height: 5px;
        width: 50px;
        border-radius: 20px;
      }
    }
    .__search {
      padding: 20px;
      .__group {
        position: relative;
        margin-top: 15px;
        input {
          width: 100%;
          padding: 0px 40px;
          border-radius: 50px;
          background-color: #fff;
          height: 40px;
          font-family: ft-semi;
          &::placeholder {
            color: #999;
            opacity: 1;
          }
        }
        .__left {
          position: absolute;
          left: 15px;
          top: 13px;
          cursor: pointer;
          &:hover + label.__tooltip {
            opacity: 1;
            visibility: visible;
            transform: translate(0px, 0px);
          }
        }

        label.__tooltip {
          position: absolute;
          background: #ffe294;
          color: #000;
          top: 47px;
          z-index: 99999;
          transform: translate(0px, 20px);
          font-size: 12px;
          padding: 9px;
          font-family: "ft-semi";
          border-radius: 4px;
          opacity: 0;
          box-shadow: 1px 1px 5px #aeaeae;
          visibility: hidden;
          &::before {
            content: "";
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-bottom: 15px solid #ffe294;
            position: absolute;
            top: -14px;
            left: 14px;
          }
        }
        .__right {
          position: absolute;
          right: 15px;
          top: 13px;
        }
      }
    }
    .__hover__elm {
      //width: 600px;
      height: 100%;
      //position: relative;
      .__listing {
        background-color: #f2f2f2;
        padding: 0px;
        height: calc(100% - 117px);
        overflow: auto;
        width: 350px;
        /* Let's get this party started */
        /*------------------------------------------------------------------
        [ Scroll CSS ]*/
        &::-scrollbar {
          width: 5px;
          height: 5px;
          position: absolute;
        }
        &::-webkit-scrollbar {
          width: 5px;
          height: 5px;
          position: absolute;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #df4f1b;
          -webkit-border-radius: 5px;
          border-radius: 5px;
          position: absolute;
        }
        li {
          padding: 10px;
          background: #fff;
          border-radius: 0px;
          margin-bottom: 12px;
          font-size: 14px;
          box-shadow: 1px 1px 6px #0000001c;
          // &::before{
          //   content: "";
          //   border-top: 11px solid transparent;
          //   border-bottom: 12px solid transparent;
          //   border-left: 13px solid #f2f2f2;
          //   position: absolute;
          //   top: 28px;
          //   right: -13px;
          // }
          .__flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 5px;
            color: var(--gray);
            span {
              &.__km {
                font-size: 12px;
                img:nth-child(1) {
                  &.__right {
                    margin-left: 5px;
                  }
                }
              }
            }
            strong {
              color: var(--black);
              font-family: "ft-semi";
            }
          }
          &:hover {
            .hide {
              visibility: visible;
              -webkit-transform: translate(290px, 0px) !important;
              transform: translate(290px, 0px) !important;
              transition: 0.5s;
              opacity: 1;
            }
          }
        }
      }
    }
    //hover element
    .hide {
      position: absolute;
      -webkit-transform: translate(280px, 0px);
      transform: translate(280px, 0px);
      top: 0;
      padding-left: 75px;
      visibility: visible;
      transition: 0.5s;
      opacity: 1;
      img {
        &.__arrow {
          position: absolute;
          left: 36px;
          transform: rotate(90deg);
          top: 31px;
          width: 41px;
        }
      }
      .__card__hover {
        padding: 20px;
        width: 515px;
        border-radius: 10px;
        font-size: 12px;
        font-family: "ft-medium";
        box-shadow: 0px 0px 10px rgb(0 0 0 / 20%);
        background-image: url("./assets/images/blur\ bg.png");
        z-index: 9;
        display: inline-block;
        height: calc(100vh - 136px);
        overflow: auto;
        overflow-x: hidden;
        border: 1.5px solid #fff;
        box-sizing: border-box;
        -webkit-backdrop-filter: blur(15px);
        backdrop-filter: blur(15px);
        background-color: rgba(255, 255, 255, 0.8);
        background-image: linear-gradient(180deg, #ffffff 0%, #e7f2fd 74%);
        //backdrop-filter: blur(10px) !important
        // box-shadow: 1px 1px 9px #00000026;
        // profile img
        // pointer-events: none;
        span {
          &.__dot {
            width: 5px;
            height: 5px;
            background-color: #333;
            border-radius: 50px;
            display: inline-block;
            vertical-align: middle;
            margin: 2px;
            opacity: 0.2;
          }
        }
        .__card__profile {
          display: flex;
          justify-content: flex-end;
          width: 100%;
          flex-direction: row-reverse;
          align-items: center;
          margin-bottom: 5px;

          div {
            &.__name {
              width: calc(100% - 50px);
              float: right;
              word-wrap: break-word;
              word-break: break-word;
              padding-left: 10px;
              label {
                font-size: 16px;
                font-family: ft-bold;
                margin-bottom: 0px;
                vertical-align: bottom;
                span {
                  font-size: 12px;
                }
              }
              .__btn__assign {
                border: 1.5px dotted var(--green);
                background-color: rgba(114, 195, 51, 0.15);
                padding: 0px 10px;
                margin-left: 10px;
                border-radius: 50px;
                color: var(--green);
                text-transform: uppercase;
                font-size: 10px;
                font-family: "ft-bold";
                margin-bottom: 10px;
              }
              img {
                vertical-align: text-top;
                margin-right: 0px;
              }
              span {
                &.__dot {
                  width: 5px;
                  height: 5px;
                  background-color: #333;
                  border-radius: 50px;
                  display: inline-block;
                  vertical-align: middle;
                  margin: 2px;
                  opacity: 0.2;
                }
              }
            }
            &.__img {
              width: 40px;
              height: 40px;
              border-radius: 50px;
              overflow: hidden;
              float: left;
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
        //truck
        .__truck {
          display: inline-flex;
          width: 100%;
          font-size: 14px;
          margin-top: 5px;
          span.__dot {
            margin-top: 10px;
          }
          &.__ft-14 {
            font-size: 14px !important;
          }
        }
        // overview
        .__Overview {
          padding: 10px;
          background: #fff;
          margin-top: 17px;
          border: 1.5px solid #eeeeee;
          border-radius: 4px;
          display: inline-flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          ul {
            position: relative;
            width: 100%;
            padding-left: 20px;
            &::before {
              content: "";
              position: absolute;
              height: calc(100% - 60px);
              width: 1px;
              background: #b7b7b75e;
              left: 5px;
              top: 29px;
            }
            li {
              background-color: transparent;
              box-shadow: none;
              padding: 0;
              position: relative;
              text-align: left;
              img.icon-set {
                position: absolute;
                left: -24px;
                top: 3px;
              }
              p {
                font-size: 12px;
                line-height: 17px;
              }
              div {
                width: 100%;
                font-size: 11px;
                &.__set {
                  display: inline-flex;
                  // white-space: nowrap;
                  align-items: center;
                  justify-content: space-between;
                  label {
                    width: inherit;
                    margin: 0;
                  }
                }
                &.current {
                  display: inline-flex;
                  white-space: nowrap;
                  align-items: center;
                  label {
                    padding: 0px 10px;
                    border-radius: 4px;
                    text-align: center;
                    width: auto;
                    margin: 0px 10px;
                    font-size: 9px;
                    &.__green {
                      background: #f7fded;
                      border: 1px solid var(--green);
                      color: var(--green);
                    }
                    &.__blue {
                      background: #e1e7ff;
                      color: #3462fd;
                      border: 1px solid #3462fd;
                    }
                  }
                }
              }
            }
          }
          .__icons {
            img {
              height: 20px;
            }
          }
          label {
            margin-bottom: 0px;
            width: 100%;
            opacity: 0.6;
            margin-top: 5px;
          }
        }
        // trip details
        .__trip {
          display: inline-flex;
          justify-content: space-between;
          width: 100%;
          margin-top: 13px;
          font-size: 14px;
          .__dropdown {
            position: relative;
            button {
              font-family: "ft-bold";
            }
            .dropdown-menu {
              position: absolute;
              top: 25px;
              right: -10px !important;
              left: inherit !important;
            }
          }
        }
        // details trip
        .__trip__details {
          margin-bottom: 20px;
          div {
            &.__side {
              margin-top: 10px;
              padding-left: 15px;
            }
            &.__multies {
              div {
                margin-bottom: 10px;
              }
            }
            &.__moving_side {
              border-left: 4px solid var(--green);
            }
            &.__parked_side {
              border-left: 4px solid var(--orange);
            }
            &.__idle_side {
              border-left: 4px solid var(--red);
            }
          }
          h5 {
            font-size: 14px;
            font-family: "ft-semi";
          }
          p {
            font-family: "ft-medium";
            color: var(--gray);
          }
        }
        //details
        .__details {
          background: #fff;
          display: inline-block;
          padding: 14px;
          border: 1.3px solid #e2e2e28c;
          border-radius: 4px;
          div {
            width: 50%;
            float: left;
            text-align: left;
            //margin-bottom: 15px;
            font-size: 12px;
            margin-top: 15px;
            label {
              width: 100%;
              margin-bottom: 0px;
              img {
                vertical-align: text-top;
              }
            }
            span {
              width: 100%;
              font-family: ft-bold;
            }
          }
        }
        .theme-btn {
          background-color: #0c67c8;
          width: 100%;
          padding: 10px;
          border-radius: 4px;
          color: #fff;
          font-family: "ft-bold";
          text-transform: uppercase;
          font-size: 14px;
          margin-bottom: 20px;
          svg {
            margin-right: 4px;
            path {
              fill: #fff;
              stroke: #fff;
            }
          }
        }
      }
    }
  }
  &.active {
    .__sidebar__in {
      height: 145px;
      -webkit-transition: 0.5s;
      transition: 0.5s;
      //transition-timing-function: cubic-bezier(.7,.06,.83,.100);
      // -webkit-transform: translate(0px, 10px);
      // transform: translate(0px, 10px);
      margin-top: auto;
    }
    .__search {
      padding: 12px !important;
      .__group {
        opacity: 0;
        visibility: hidden;
        transition: 0.5s;
        height: 0;
        display: none;
      }
    }
    .__listing {
      opacity: 0;
      visibility: hidden;
      transition: 0.5s;
      height: 0;
      display: none;
      -webkit-transition: 0.5s;
      transition: 0.5s;
    }
    app-map,
    app-drivers,
    app-trips {
      height: initial;
    }
    // .__profile {
    //   //margin-top: inherit;
    // }
  }
}
//sidebar

//profile
.__profile {
  width: 350px;
  border-radius: 8px;
  background-color: #fff;
  float: left;
  margin-top: 20px;
  padding: 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 9;
  .__country {
    float: right;
    font-family: ft-bold;
    padding-top: 4px;
    svg {
      margin-left: 5px;
    }
    img {
      margin-top: -3px;
      margin-right: 5px;
    }
  }
}
//profile
//filters
.__filters {
  float: right;
  width: calc(100% - 420px);
  margin-top: auto;
  margin-bottom: 25px;
  z-index: 2;
  ul {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    width: 100%;
    padding: 0px;
    padding-top: 0px !important;
    background: #fff;
    -webkit-box-shadow: 1px 1px 10px #e5e5e5;
    box-shadow: 1px 1px 10px #e5e5e5;
    height: 60px;
    background: linear-gradient(
      130.48deg,
      rgba(255, 255, 255, 0.33) 6.24%,
      rgba(255, 255, 255, 0.132) 146.95%
    );
    border: 1px solid #fff;
    box-sizing: border-box;
    backdrop-filter: blur(100px);
    border-radius: 8px;
    line-height: 60px;
    li {
      height: 100%;
      padding: 0px 15px;
      width: 100%;
      text-align: center;
      border-right: 1.4px solid #fff;
      white-space: nowrap;
      // &.__label{
      //   width: 160px;
      // }
      // &.__clear{
      //   width: 160px;
      // }
      // for driver
      &.__clear {
        border-right: 0px;
      }
      &.__driver {
        .custom-chck {
          margin-left: 20px;
          input {
            &:checked {
              ~ {
                .checkmark {
                  background-color: var(--orange);
                  border-color: var(--orange) !important;
                  &:after {
                    display: block;
                    border-color: #fff;
                  }
                }
              }
            }
          }
          .checkmark {
            border-radius: 50%;
            &:after {
              content: "";
              position: absolute;
              display: block;
              left: 6px;
              top: 2px;
              width: 5px;
              height: 10px;
              border: 0.5px solid #f1f1f1;
              border-width: 0 2px 2px 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
            }
          }
          &:hover {
            input {
              ~ {
                .checkmark {
                  background-color: var(--orange);
                  border-color: var(--orange);
                }
              }
            }
          }
        }
      }
    }
  }
}
//filters

//select__dropdown
.dropdown {
  a {
    font-family: ft-bold;
    color: var(--black);
    span {
      color: var(--orange);
      svg {
        margin-left: 10px;
      }
    }
    &:hover {
      text-decoration: none;
    }
  }
  .dropdown-menu {
    display: block;
    visibility: hidden;
    opacity: 0;
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
    -webkit-transition: 0.5s ease all;
    transition: 0.5s ease all;
    left: inherit;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: inherit;
    bottom: 4px;
    width: 85%;
    z-index: 99999999999999;
    border: none;
    background-color: #fff !important;
    padding: 0px;
    margin-bottom: 10px;
    //padding-top: 10px;
    .__relative {
      position: relative;
      .__arrow {
        position: absolute;
        z-index: -1;
        bottom: -14px;
        width: 70px;
        right: 0;
      }
    }
    &.show {
      display: block;
      visibility: visible;
      opacity: 1;
      -webkit-transform: translateY(0px);
      transform: translateY(-70px);
      -webkit-transition: 0.5s ease all;
      transition: 0.5s ease all;
      background: #fff;
      border-radius: 8px;
      width: 100%;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    }
    a {
      padding: 10px;
      font-family: ft-semi;
      color: var(--black);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 10px;
      padding-top: 10px;
      border-radius: 8px;
      position: relative;
      .__mark {
        float: right;
        svg {
          width: 16px;
          position: absolute;
          right: 14px;
          top: 12px;
          path {
            fill: var(--orange);
          }
        }
      }
      &:hover {
        background-color: #f2f2f2;
      }
    }
  }
}
//select__dropdown
/*custom-chck */
.custom-chck {
  display: inline-flex;
  flex-direction: row-reverse;
  cursor: pointer;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked {
      ~ {
        .checkmark {
          background-color: #fff;
          border-color: var(--orange) !important;
          &:after {
            display: block;
          }
        }
      }
    }
  }
  &:hover {
    input {
      ~ {
        .checkmark {
          //background-color: var(--orange);
          border-color: var(--orange);
        }
      }
    }
  }
  .checkmark {
    height: 19px;
    width: 19px;
    background-color: #fff;
    border: 1.5px solid #fff;
    border-radius: 4px;
    float: left;
    margin-right: 10px;
    position: relative;
    box-shadow: 1px 1px 5px #0000003b;
    margin-top: 2px;
    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 6px;
      top: 2px;
      width: 5px;
      height: 10px;
      border: 0.5px solid #f47824;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

//* Menu sidebar
/*  css selector for fun ('.') */
$nav_items: 5;
$nav_items_width: (100 / $nav_items) * 1%;

.nav_wrap {
  margin-top: auto;
  background-color: #fff;
  border-radius: 0px 0px 8px 8px;
  box-shadow: -1px -7px 15px #00000038;
  padding: 0px 10px;
  z-index: 1;
  &:hover {
    .nav_item:last-child {
      &:before,
      &:after {
        opacity: 1;
      }
    }
  }
  .nav_list {
    position: relative;
    display: flex;
    align-items: center;
    .nav_item {
      flex: 0 0 auto;
      line-height: 50px;
      width: $nav_items_width;
      text-align: center;
      &.active {
        .nav_link {
          color: var(--orange);
          svg {
            path {
              fill: var(--orange);
              stroke: var(--orange);
            }
          }
        }
      }

      &:last-child {
        &:before,
        &:after {
          content: "";
          position: absolute;
          z-index: 0;
          transition: left 0.6s ease;
          opacity: 1;
        }
        &:before {
          top: 0;
          left: 0;
          width: $nav_items_width;
          height: 4px;
          //background: var(--orange);
          background: linear-gradient(
            101.6deg,
            #ff7c08 28.48%,
            #cd2b13 187.34%
          );
          border-radius: 6px;
        }
        // &:after {
        //   border:10px solid transparent;
        //   border-top:10px solid var(--orange);
        //   top: 0;
        //   left:50%;
        //   margin-left:-10px;
        // }
      }
      .nav_link {
        position: relative;
        z-index: 5;
        display: block;
        padding: 10px 5px;
        color: var(--black);
        text-decoration: none;
        font-family: "ft-semi";
        //width: 48px;
        margin: 0 auto;
        &:hover {
          text-decoration: none;
          color: var(--orange);
          svg {
            g {
              opacity: 1;
            }
            path {
              fill: var(--orange);
              opacity: 1;
              //stroke: var(--orange);
            }
          }
        }
      }
    }
  }
}

@for $i from 1 through $nav_items {
  .nav_item:nth-child(#{$i}).active ~ .nav_item:last-child:before {
    left: ($nav_items_width * $i) - $nav_items_width;
  }
  .nav_item:nth-child(#{$i}).active ~ .nav_item:last-child:after {
    left: ($nav_items_width * $i + $nav_items_width/2) - $nav_items_width;
  }
}
.nav_item:last-child.active:before {
  left: (100% - $nav_items_width);
}
.nav_item:last-child.active:after {
  left: (100% - $nav_items_width);
}

@for $i from 1 through $nav_items {
  .nav_item:nth-child(#{$i}):hover ~ .nav_item:last-child:before {
    left: ($nav_items_width * $i) - $nav_items_width !important;
  }
  .nav_item:nth-child(#{$i}):hover ~ .nav_item:last-child:after {
    left: ($nav_items_width * $i + $nav_items_width/4) -
      $nav_items_width !important;
  }
}

.nav_item:last-child:hover:before {
  left: (100% - $nav_items_width) !important;
}
.nav_item:last-child:hover:after {
  left: (100% - $nav_items_width + $nav_items_width/2) !important;
}

//inside component
app-map,
app-drivers,
app-trips {
  height: inherit;
}

.gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom .gmnoprint {
  top: -70px !important;
  left: -12px !important;
}
.cursor {
  cursor: pointer;
}

.agm-info-window-content {
  font-family: ft-semi;
  padding: 10px;
  a.__track {
    width: 100%;
    text-align: center;
    display: inline-block;
    margin-top: 5px;
  }
}
.gm-ui-hover-effect {
  top: -4px !important;
  right: -2px !important;
  img {
    width: 20px !important;
    height: 22px !important;
    margin: 5px !important;
  }
}

// schedule email
.__form {
  .__card__hover {
    .__back {
      margin-bottom: 5px;
      label {
        width: 100%;
        font-size: 16px;
        a {
          margin-right: 10px;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    .__group {
      position: relative;
      margin-bottom: 15px;
      &.__half {
        display: flex;
        justify-content: space-between;
        .__child {
          position: relative;
          width: 48%;
          .__arrows {
            position: absolute;
            right: 0;
            top: 6px;
            right: 0px;
            color: #e1e6e9;
            a {
              margin: 0px 10px;
            }
          }
        }
      }
      input {
        width: 100%;
        padding: 0px 15px;
        border-radius: 4px;
        background-color: #fff;
        height: 40px;
        font-family: ft-semi;
        border: 1.4px solid #e1e6e9;
        font-size: 14px;
        &::placeholder {
          color: #999;
          opacity: 0.5;
        }
      }
      .__right {
        position: absolute;
        right: 15px;
        top: 8px;
        width:25px
      }
    }
    .__scroll_hu {
      max-height: 300px;
      overflow: auto;
      overflow-x: hidden;
      .__edit {
        margin-top: 10px;
        &.__disabled {
          opacity: 0.5;
        }
        .__list {
          display: flex;
          justify-content: space-between;
          .__slot {
            width: auto;
            padding-right: 5px;
            display: inherit;
            span {
              width: 60px;
              height: 60px;
              margin-right: 10px;
              border: 1.9px dashed var(--gray);
              border-radius: 6px;
              text-align: center;
              padding-top: 15px;
              font-size: 14px;
              font-family: "ft-bold";
              line-height: 11px;
              &.__today {
                label {
                  color: var(--gray);
                  font-family: "ft-semi" !important;
                }
              }
              &.__time {
                font-family: "ft-bold";
                line-height: 16px;
              }
            }
          }
          .__upcoming {
            width: 100%;
            .__control {
              height: 100%;
              position: relative;
              border-top: 1.5px solid var(--green);
              border-bottom: 1.5px solid var(--green);
              line-height: 10px;
              label {
                font-size: 18px;
                font-family: "ft-semi";
                margin-bottom: 0px;
                margin-top: 17px;
              }
              p {
                font-size: 12px;
                color: var(--gray);
              }
              .__action {
                position: absolute;
                right: 0px;
                top: 10px;
                button {
                  margin-left: 10px;
                }
              }
            }
          }
        }
      }
    }
    .__send {
      font-size: 14px;
      margin-bottom: 14px;
      .custom-chck {
        margin-bottom: 0px;
        .checkmark {
          margin-bottom: 0px;
          border: 1.5px solid var(--gray);
        }
      }
    }
  }
}

//multiple dropdown
.__multi {
  position: absolute;
  bottom: 57px;
  background: #fff;
  width: 200px;
  right: 0;
  border-radius: 6px;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 20%);
  visibility: hidden;
  transform: translate(0px, -20px);
  opacity: 0;
  transition: 0.3s;
  &.active {
    visibility: visible;
    transform: translate(0px, 0px);
    opacity: 1;
    transition: 0.3s;
  }
  .__arrow {
    position: absolute;
    bottom: -10px;
    right: 11px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #fff;
  }
  .__dropdown {
    display: flex;
    flex-direction: column;
    padding-bottom: 1px;
    a {
      padding: 10px;
      border-radius: 8px;
      position: relative;
      .__mark {
        float: right;
        svg {
          width: 16px;
          position: absolute;
          right: 14px;
          top: 12px;
          path {
            fill: var(--orange);
          }
        }
      }
      img {
        width: 20px;
      }
      &:hover {
        background-color: #f1f1f1;
      }
    }
  }
}

//load more
.more {
  margin: 0 auto;
  display: block;
  margin-bottom: 30px;
  background-color: var(--orange);
  color: #fff;
  padding: 0px 10px;
  height: 30px;
  border-radius: 4px;
  border: 2px solid orange;
}

// tracking
.__tracking {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-top: auto;
  .__head {
    background-color: #081940;
    padding: 20px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
      vertical-align: middle;
    }
    .__support {
      float: right;
      text-align: right;
      font-family: "ft-semi";
      line-height: 18px;
      span {
        color: var(--red);
        img {
          width: 16px;
        }
      }
      a {
        color: #fff;
        line-height: 0px;
      }
    }
  }
  .__content {
    padding: 15px;
    overflow: auto;
    overflow-x: hidden;
    &::-scrollbar {
      width: 5px;
      height: 5px;
      position: absolute;
    }
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      position: absolute;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #df4f1b;
      -webkit-border-radius: 5px;
      border-radius: 5px;
      position: absolute;
    }
    .__border {
      padding-bottom: 10px;
      border-bottom: 1px solid #e7e5e5;
      margin-bottom: 10px;
    }
    .select.__flex {
      align-items: center;
      border: 1px solid #e7e5e5;
      border-radius: 4px;
      padding: 5px 10px;
      margin: 10px 0px;
      label {
        margin-bottom: 0px;
      }
      .__dropdown {
        position: relative;
        button {
          color: var(--orange);
        }
        svg {
          margin-left: 10px;
        }
        .dropdown-menu {
          border: 0;
          box-shadow: 1px 1px 11px #9d9d9d70;
          right: 0;
          top: 0 !important;
          left: inherit !important;
          transform: translate(11px, 40px) !important;
          transition: 0.5s;
          width: 160px;
          a {
            color: var(--gray);
            padding: 2px 10px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.08);
            &:hover {
              color: var(--black);
            }
            &:last-of-type {
              border-bottom: 0;
            }
          }
          &.show {
            transform: translate(11px, 25px) !important;
            transition: 0.5s;
          }
        }
      }
    }
    .__flex {
      display: flex;
      justify-content: space-between;
      .__light {
        color: var(--gray);
      }
      span {
        font-family: "ft-semi";
      }
    }
    .__location {
      margin-bottom: 10px;
      margin-top: 10px;
      p {
        font-size: 12px;
        line-height: 20px;
        font-family: "ft-medium";
        color: #000;
      }
      svg {
        margin-right: 5px;
      }
    }
  }
}
.__moving {
  color: var(--green);
  border: 1px solid;
  padding: 2px 13px;
  border-radius: 16px;
  background: #f7fded;
  font-size: 12px;
  font-family: ft-semi;
  line-height: 19px;
  margin-bottom: 0px;
  text-transform: capitalize;
}
.__parked {
  border: 1px solid;
  padding: 2px 13px;
  border-radius: 16px;
  font-size: 12px;
  font-family: ft-semi;
  line-height: 19px;
  margin-bottom: 0px;
  color: var(--orange) !important;
  border-color: var(--orange) !important;
  background: #fef1e6;
  text-transform: capitalize;
}
.__eye {
  border: 1px solid;
  padding: 2px 5px;
  border-radius: 100%;
  font-size: 12px;
  font-family: ft-semi;
  line-height: 19px;
  margin-bottom: 0px;
  color: var(--orange) !important;
  border-color: #ffd0a6 !important;
  transition: 0.25s all ease-in;

  &:hover {
    background-color: #ffd9b8 !important;
  }
}
.__idle {
  border: 1px solid;
  padding: 2px 13px;
  border-radius: 16px;
  font-size: 12px;
  font-family: ft-semi;
  line-height: 19px;
  margin-bottom: 0px;
  color: var(--gray) !important;
  border-color: var(--gray) !important;
  background: #66666624;
  text-transform: capitalize;
}
.ft-sm {
  font-size: 12px;
}

//toggle tracking

nav.menu_open a {
  margin-top: 7px;
  background: #fff;
  display: flex;
  flex-direction: column;
  box-shadow: -2px 0px 9px #00000042;
  img {
    position: absolute;
    left: -50px;
    top: -5px;
    width: 50px;
  }
}
.menu_open {
  position: fixed;
  right: 30px;
  top: 64px;
  z-index: 9;
}

.menu-itemss {
  margin-left: -40px;
  //position: absolute;
  visibility: hidden;
  top: 50px;
  color: white;
  text-align: left;
  padding: 10px;
  transform: translate3d(0, 0, 0);
  transition: transform ease-out 200ms;
  transition-duration: 200ms;
  transition-timing-function: ease-out;
  right: -40px;
  width: 200px;
  border-radius: 6px;
  label {
    margin-bottom: 0px;
  }
  &::before {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    position: absolute;
    content: "";
    border-right: 10px solid white;
    left: -9px;
  }
  &:hover {
    text-decoration: none;
  }
}

.menu-open-button {
  border-radius: 100%;
  width: 60px;
  height: 60px;
  position: absolute;
  top: -63px;
  color: white;
  text-align: center;
  line-height: 80px;
  transform: translate3d(0, 0, 0);
  transition: transform ease-out 200ms;
  right: -12px;
}

.menu-open {
  display: none;
}
.menu_open svg {
  width: 100%;
  height: inherit;
}
.menu_open input {
  display: none;
}
.menu-itemss:nth-child(3) {
  transition-duration: 180ms;
}
.menu-itemss:nth-child(4) {
  transition-duration: 180ms;
}
.menu-itemss:nth-child(5) {
  transition-duration: 180ms;
}
.menu-itemss:nth-child(6) {
  transition-duration: 180ms;
}

.menu-open-button {
  z-index: 2;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-duration: 400ms;
  transform: scale(1.1, 1.1) translate3d(0, 0, 0);
  cursor: pointer;
}

.menu-open:checked + .menu-open-button {
  transition-timing-function: linear;
  transition-duration: 200ms;
  transform: scale(0.8, 0.8) translate3d(0, 0, 0);
}

.menu-open:checked ~ .menu-itemss {
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  visibility: visible;
}
.menu-open:checked ~ .menu-itemss:nth-child(3) {
  transition-duration: 170ms;
  //transform: translate3d(-6px, 60px, 0px);
  visibility: visible;
}
.menu-open:checked ~ .menu-itemss:nth-child(4) {
  transition-duration: 250ms;
  //transform: translate3d(-6px, 170px, 0);
  visibility: visible;
}
.menu-open:checked ~ .menu-itemss:nth-child(5) {
  transition-duration: 330ms;
  //transform: translate3d(-6px, 270px, 0);
  visibility: visible;
}
.menu-open:checked ~ .menu-itemss:nth-child(6) {
  transition-duration: 410ms;
  //transform: translate3d(-6px, 370px, 0);
  visibility: visible;
}
.menu-open:checked ~ .menu-itemss:nth-child(7) {
  transition-duration: 540ms;
  //transform: translate3d(-6px, 370px, 0);
  visibility: visible;
}
.cross_share {
  position: absolute;
  right: 0;
  opacity: 0;
  top: 11px;
}
.menu-open:checked ~ .menu-open-button .tracking {
  opacity: 0;
}
.menu-open:checked ~ .menu-open-button .cross_share {
  opacity: 1;
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
}

.__relative {
  position: relative;
}
